import logo from "./logo.svg";
import "./App.scss";
import futureBG from "./FutureBG.jpeg";
import ReserveBG from "./ReserveBG.jpeg";
import AdvocateBG from "./AdvocateBG.jpeg";
import TeamMember1 from "./TeamMember1.jpeg";
import BidBG from "./BidBG.jpg";
function App() {
  return (
    <div className="bg-black">
      <div className="max-w-[1700px] mx-auto bg-gray-100">
        {/* Hero Section */}
        <header className="relative h-screen flex items-center justify-center text-white">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <img
            src={futureBG}
            alt="Nashville cityscape with iconic landmarks and vibrant city lights"
            className="absolute inset-0 w-full h-full object-cover"
          />
          <div className="relative z-10 text-center">
            <h1 className="text-6xl font-bold mb-4">NOW IS THE TIME</h1>
            <p className="text-3xl tracking-widest">N A S H V I L L E , T N</p>
          </div>
        </header>

        {/* Bid Section */}
        <section
          className="py-20"
          style={{ background: "linear-gradient(to right, #87CEEB, #4682B4)" }}
        >
          <div className="container mx-auto px-8 md:flex md:items-center">
            <div className="md:w-1/2 md:pr-8 mb-8 md:mb-0">
              <img
                src={ReserveBG}
                className="w-full h-auto rounded-lg shadow-lg md:hidden mb-8"
                alt="Visual representation of the bid overview"
              />
              <h2 className="text-4xl font-bold mb-8 text-white">
                Bid Overview
              </h2>
              <p className="text-lg leading-relaxed text-white">
                Our mission is to transcend barriers and set new standards of
                excellence in women's soccer through innovation and
                representation within our community and beyond. We are dedicated
                to creating a dynamic, inclusive environment where every player
                can thrive and every fan feels connected. Our committed pursuit
                of excellence throughout every facet of the franchise is aimed
                at elevating the game experience to inspire a new generation of
                athletes and supporters. Together, we will contribute to a
                legacy of empowerment, unity, and unparalleled passion for this
                universal game.
              </p>
            </div>
            <div className="md:w-1/2 md:pl-8 hidden md:block">
              <img
                src={ReserveBG}
                className="w-full h-auto rounded-lg shadow-lg"
                alt="Visual representation of the bid overview"
              />
            </div>
          </div>
        </section>

        {/* NWSL Nashville – Leading the Way Section */}
        <section className="py-20 bg-white md:bg-white relative">
          <div className="container mx-auto px-8 md:flex md:items-center">
            <div className="md:w-1/2 md:pr-8 hidden md:block">
              <img
                src={futureBG}
                alt="Diverse group of soccer fans cheering"
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
            <div className="md:w-1/2 md:pl-8 mb-8 md:mb-0 relative z-10">
              <h2 className="text-4xl font-bold mb-8">
                NWSL Nashville – Leading the Way
              </h2>
              <div className="md:w-1/2 md:pl-8 mb-4">
                <img
                  src={futureBG}
                  className="w-full h-auto rounded-lg shadow-lg md:hidden"
                  alt="Diverse group of soccer fans cheering"
                />
              </div>
              <div className="space-y-8">
                <p className="text-lg leading-relaxed">
                  Tennessee boasts a strong sports culture with highly
                  demonstrable fan support for all its teams across sports
                  platforms. As one of the fastest-growing and most dynamic
                  cities in the United States, Nashville's economic
                  opportunities, cultural vibrancy, quality of life, and rapid
                  growth strategically positions it as the ideal location to
                  cultivate and grow what would become Tennessee's first women's
                  professional franchise.
                </p>
                <p className="text-lg leading-relaxed">
                  Representing more than just athletic competition, women's
                  sports represent a broad spectrum of values and ideals. In
                  alignment with the mission of the NWSL, our team will further
                  advance equity and diversity in the sport and foster growth of
                  both the game and league, domestically and internationally,
                  leveraging innovative approaches to further support the
                  development of players, our franchise, the league and the
                  community we serve. Our franchise will be a beacon for change
                  and empowerment, influencing positive shifts in both sports
                  and broader societal roles.
                </p>
              </div>
            </div>
          </div>
          <div className="absolute inset-0 bg-black opacity-15 md:hidden"></div>
        </section>

        {/* Managing Partner Section */}
        <section
          className="py-20"
          style={{ background: "linear-gradient(to right, #87CEEB, #4682B4)" }}
        >
          <div className="container mx-auto px-8 md:flex md:items-center">
            <div className="md:w-1/2 md:pr-8 mb-8 md:mb-0">
              <img
                src={TeamMember1}
                className="w-full h-auto rounded-lg shadow-lg md:hidden mb-8"
                alt="Portrait of Lonnie Murray, Managing Partner"
              />
              <h2 className="text-4xl font-bold mb-8 text-white">
                Managing Partner
              </h2>
              <p className="text-lg leading-relaxed text-white">
                Lonnie Murray is our Managing Partner, and the first Black woman
                to be certified by the Major League Baseball Players Association
                as a player-agent. After 17-years in the nonprofit sector,
                largely focused on after-school sports programming in
                underserved communities, Lonnie transitioned on to helping young
                men pursue careers as Major League Baseball players and
                continues to mentor countless others in their pursuit to work in
                sports. Her commitment to community earned her recognition as
                California Woman of the Year in 2020, and in 2023 she was
                awarded for her significant role in the historic unionization of
                Minor League Baseball players. Lonnie serves on the Baseball
                Hall of Fame Advisory Board and in May of 2024 the Hall of Fame
                added an interactive exhibit featuring her journey and
                contribution to Major League Baseball.
              </p>
            </div>
            <div className="md:w-1/2 md:pl-8 hidden md:block">
              <img
                src={TeamMember1}
                className="w-full h-auto rounded-lg shadow-lg"
                alt="Portrait of Lonnie Murray, Managing Partner"
              />
            </div>
          </div>
        </section>

        {/* Pledge Section */}
        <section className="py-20 bg-white md:bg-white relative">
          <div className="container mx-auto px-8 md:flex md:items-center">
            <div className="md:w-1/2 md:pr-8 hidden md:block">
              <img
                src={AdvocateBG}
                alt="Diverse group of soccer fans cheering"
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
            <div className="md:w-1/2 md:pl-8 mb-8 md:mb-0 relative z-10">
              <h2 className="text-4xl font-bold mb-8">Become an Advocate</h2>
              <div className="md:w-1/2 md:pl-8 mb-4">
                <img
                  src={AdvocateBG}
                  className="w-full h-auto rounded-lg shadow-lg md:hidden"
                  alt="Diverse group of soccer fans cheering"
                />
              </div>
              <p className="text-lg leading-relaxed mb-8">
                Support our efforts to bring the NWSL to Nashville. Enter your
                details below to stay informed of our activities and progress,
                and learn of ways to help our city secure its first professional
                women's soccer team.
              </p>
              <div className="bg-white p-8 rounded-lg shadow-lg">
                {/* <form className="space-y-4">
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="w-full p-2 border rounded"
                    disabled
                  />
                  <input
                    type="email"
                    placeholder="Email Address"
                    className="w-full p-2 border rounded"
                    disabled
                  />
                  <button
                    type="submit"
                    className="w-full bg-blue-500 text-white p-2 rounded font-bold"
                    disabled
                  >
                    Join the Movement
                  </button>
                </form>
                <p className="text-center mt-4 text-gray-600">
                  Pledge sign-up coming soon!
                </p> */}
                <form
                  action="https://app.us12.list-manage.com/subscribe/post?u=527b7c74c480cd3abf1c877a3&amp;id=457d0e4b02&amp;f_id=009be4e0f0"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  target="_blank"
                  className="space-y-4"
                >
                  <div id="mc_embed_signup_scroll">
                    <div>
                      <input
                        type="text"
                        name="FNAME"
                        id="mce-FNAME"
                        className="w-full p-2 border rounded mb-3"
                        placeholder="Full Name"
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        name="EMAIL"
                        id="mce-EMAIL"
                        required=""
                        className="w-full p-2 border rounded mb-3"
                        placeholder="Email Address"
                      />
                    </div>
                    <div id="mce-responses">
                      <div
                        id="mce-error-response"
                        style={{ display: "none" }}
                      ></div>
                      <div
                        id="mce-success-response"
                        style={{ display: "none" }}
                      ></div>
                    </div>
                    <div
                      aria-hidden="true"
                      style={{ position: "absolute", left: "-5000px" }}
                    >
                      <input
                        type="text"
                        name="b_527b7c74c480cd3abf1c877a3_457d0e4b02"
                        tabIndex="-1"
                      />
                    </div>
                    <div>
                      <input
                        type="submit"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        value="Join The Movement"
                        className="w-full bg-blue-500 text-white p-2 rounded font-bold"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="absolute inset-0 bg-black opacity-15 md:hidden"></div>
        </section>

        {/* Ticket Waiting List Section */}
        <section className="py-20 bg-gray-100 md:bg-gray-100 relative">
          <div className="container mx-auto px-8 md:flex md:items-center">
            <div className="md:w-1/2 md:pr-8 relative z-10">
              <h2 className="text-4xl font-bold mb-8">Reserve Your Seat</h2>
              <img
                src={ReserveBG}
                className="w-full h-auto rounded-lg shadow-lg mb-4 md:hidden"
                alt="Excited fans in a soccer stadium"
              />
              <p className="text-lg leading-relaxed mb-8">
                Are you ready for the rush and excitement that our team will
                bring to our city? Enter your details below to join the waiting
                list for season tickets for our inaugural season.
              </p>
              <div className="bg-white p-8 rounded-lg shadow-lg">
                <form
                  action="https://app.us12.list-manage.com/subscribe/post?u=527b7c74c480cd3abf1c877a3&amp;id=457d0e4b02&amp;f_id=0094e4e0f0"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="space-y-4"
                  target="_blank"
                >
                  <div id="mc_embed_signup_scroll">
                    <div>
                      <input
                        type="text"
                        name="EMAIL"
                        id="mce-EMAIL"
                        placeholder="Email Address"
                        className="w-full p-2 border rounded mb-3"
                        // disabled
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        name="FNAME"
                        id="mce-FNAME"
                        placeholder="Full Name"
                        className="w-full p-2 border rounded mb-3"
                        // disabled
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        name="MMERGE7"
                        id="mce-MMERGE7"
                        placeholder="Phone Number"
                        className="w-full p-2 border rounded mb-3"
                        // disabled
                      />
                    </div>
                    <div id="mce-responses">
                      <div
                        id="mce-error-response"
                        style={{ display: "none" }}
                      ></div>
                      <div
                        id="mce-success-response"
                        style={{ display: "none" }}
                      ></div>
                    </div>
                    <div
                      aria-hidden="true"
                      style={{ position: "absolute", left: "-5000px" }}
                    >
                      <input
                        type="text"
                        name="b_527b7c74c480cd3abf1c877a3_457d0e4b02"
                        tabIndex="-1"
                        // value=""
                      />
                    </div>
                    <div>
                      <input
                        type="submit"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="w-full bg-green-500 text-white p-2 rounded font-bold"
                        value="Join Waiting List"
                      />
                    </div>
                  </div>
                </form>
                {/* <form className="space-y-4">
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="w-full p-2 border rounded"
                    disabled
                  />
                  <input
                    type="email"
                    placeholder="Email Address"
                    className="w-full p-2 border rounded"
                    disabled
                  />
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    className="w-full p-2 border rounded"
                    disabled
                  />
                  <button
                    type="submit"
                    className="w-full bg-green-500 text-white p-2 rounded font-bold"
                    disabled
                  >
                    Join Waiting List
                  </button>
                </form> */}
              </div>
            </div>
            <div className="md:w-1/2 md:pl-8 hidden md:block">
              <img
                src={ReserveBG}
                alt="Excited fans in a soccer stadium"
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
          <div className="absolute inset-0 bg-black opacity-15 md:hidden"></div>
          <img
            src={ReserveBG}
            alt="Excited fans in a soccer stadium"
            className="absolute inset-0 w-full h-full object-cover -z-10 md:hidden"
          />
        </section>

        {/* Footer */}
        <footer className="bg-gray-800 text-white py-8">
          <div className="container mx-auto px-8 text-center">
            <p>&copy; 2024 All rights reserved.</p>
            <div className="mt-4 space-x-4">
              <a href="#" className="text-white hover:text-gray-300">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="text-white hover:text-gray-300">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="text-white hover:text-gray-300">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
